<template>
  <v-list-item>
    <v-list-item-icon class="align-end">
      <v-icon>{{ icon }}</v-icon>
      <span class="pl-2 text-subtitle">{{ cn }}</span>
    </v-list-item-icon>
    <v-list-item-content class="text-right">
      <v-list-item-title>
        {{ title }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  name: "HistoryItem",
  props: {
    cn: String,
    title: String,
    icon: String,
  },
  data: () => ({}),
  methods: {},
};
</script>
<style scoped></style>
